<div class="mx-0 p-8 justify-content-center" style="background: #ffffff">
    <div class="grid justify-content-between">
        <div class="col-12 md:col-5">
            <div class="flex align-items-center justify-content-center md:justify-content-start md:mb-0 mb-3 cursor-pointer">
                <a href="">
                    <img class="p-1"
                        src="assets/layout/images/logo-with-text.png"
                        alt="Rimap Logo" height="100">
                </a>
            </div>
            <p class="text-center md:text-left text-primary mt-5"><b>{{'contact' | translate}}: </b>
                <a class="text-primary" href="mailto:yufe@uniri.hr">{{'rimap-email' | translate}}</a>
            </p>
            <p class="text-primary text-center md:text-left">
                © {{currentYear}}{{'all-rights-reserved' | translate}}</p>
        </div>

        <div class="col-12 md:col-7 lg:col-7">
            <div class="grid text-center md:text-left">
                <div class="col-12 md:col-6">
                    <h4 class="font-medium text-2xl line-height-3 mb-3 text-100 text-primary">{{ 'useful-links' | translate }}</h4>
                    <a [routerLink]="['']"
                       class="line-height-3 text-xl block cursor-pointer mb-2 text-primary">{{'home' | translate}}</a>
                    <a [routerLink]="['about-us']"
                       class="line-height-3 text-xl block cursor-pointer mb-2 text-primary">{{'about-us' | translate}}</a>
                    <a [routerLink]="['/news']"
                       class="line-height-3 text-xl block cursor-pointer mb-2 text-primary">{{'news.default' | translate}}</a>
                    <a [routerLink]="['/login']"
                       class="line-height-3 text-xl block cursor-pointer mb-2 text-primary">{{'auth.login' | translate}}</a>
                    <a [routerLink]="['/registration']"
                       class="line-height-3 text-xl block cursor-pointer text-primary">{{'auth.registration' | translate}}</a>
                </div>

                <div class="col-12 md:col-6 mt-4 md:mt-0">
                    <h4 class="font-medium text-2xl line-height-3 mb-3 text-100 text-primary">{{ 'entities-view' | translate }}</h4>
                    <a [routerLink]="['companies']"
                       class="line-height-3 text-xl block cursor-pointer mb-2 text-primary">{{'companies.default' | translate}}</a>
                    <a [routerLink]="['scientists']"
                       class="line-height-3 text-xl block cursor-pointer mb-2 text-primary">{{'scientists.default' | translate}}</a>
                    <a [routerLink]="['public-organizations']"
                       class="line-height-3 text-xl block cursor-pointer mb-2 text-primary">{{'organizations.default' | translate}}</a>
                    <a [routerLink]="['companies/research-problems']"
                       class="line-height-3 text-xl block cursor-pointer mb-2 text-primary">{{'companies.company-research-problems' | translate}}</a>
                    <a [routerLink]="['public-organizations/research-problems']"
                       class="line-height-3 text-xl block cursor-pointer mb-2 text-primary">{{'organizations.organization-research-problems' | translate}}</a>
                    <a [routerLink]="['companies/job-offers']"
                       class="line-height-3 text-xl block cursor-pointer mb-2 text-primary">{{'companies.company-job-offers' | translate}}</a>
                    <a [routerLink]="['scientists/equipment']"
                       class="line-height-3 text-xl block cursor-pointer mb-2 text-primary">{{'scientists.scientist-equipment' | translate}}</a>
                    <a [routerLink]="['scientists/specific-knowledge']"
                       class="line-height-3 text-xl block cursor-pointer mb-2 text-primary">{{'scientists.scientist-specific-knowledge' | translate}}</a>
                </div>
            </div>
        </div>
    </div>
</div>

