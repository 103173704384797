<div class="layout-topbar">
    <a class="layout-topbar-logo">
        <img
            src="assets/layout/images/{{layoutService.config.colorScheme === 'light' ? 'logo' : 'logo'}}.png"
            alt="logo">
    </a>

    <button #menubutton class="p-link layout-menu-button layout-topbar-button" (click)="layoutService.onMenuToggle()">
        <i class="pi pi-bars"></i>
    </button>

    <button #topbarmenubutton class="p-link layout-topbar-menu-button layout-topbar-button"
            (click)="layoutService.showProfileSidebar()">
        <i class="pi pi-ellipsis-v"></i>
    </button>

    <div #topbarmenu class="layout-topbar-menu"
         [ngClass]="{'layout-topbar-menu-mobile-active': layoutService.state.profileSidebarVisible}">
        <button (click)="logout()" class="p-link layout-topbar-button">
            <i class="pi pi-sign-out"></i>
            <span>{{ 'logout' | translate }}</span>
        </button>
    </div>
</div>
