<div class="surface-0 flex">

    <div id="home" class="overflow-hidden" style="width: 100%">
        <p-menubar [model]="menuItems">
            <ng-template pTemplate="start">
                <a href="">
                    <img src="assets/layout/images/logo.png"
                         alt="Sakai Logo" height="40" class="mr-0 lg:mr-2">
                </a>
            </ng-template>
            <ng-template pTemplate="end">
                <div class="flex ql-align-center">
                    <language-selector></language-selector>
                </div>
            </ng-template>
        </p-menubar>
        <div class="landing-wrapper" style="width: 100%; height: 100%">
            <router-outlet></router-outlet>

            <public-footer></public-footer>
        </div>
    </div>
</div>
